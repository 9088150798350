import Img from "gatsby-image"
import React from 'react';
import styled from '@emotion/styled';
import { theme } from './theme';

import { H3, TextSmall } from './typography';
import { ICard, IPriceCard, IProfile } from '../shared/contentfu.interface';

const StyledCard = styled.div`
  min-width: 200px;
  height: 480px;
  border-radius: 3px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${theme.colors.brandLight.forth};
  transition: transform .2s;

    &:hover {
        transform: scale(1.05);
    }
`;

const Profile = styled.div`
  min-width: 200px;
  height: 550px;
  border-radius: 3px;
  border: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${theme.colors.brandLight.forth};
`;

const StyledHtml = styled.div`
    p {
    width: 100%;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    margin: 0;
    color: ${theme.colors.brandLight.third};
    }
`

const StyledImg = styled(Img)`
    height: 300px;
    width: 100%;
    object-fit: cover;
    object-position: center;
`

const StyledProfileImg = styled(Img)`
    height: 400px;
    width: 100%;
    object-fit: cover;
    object-position: center;
`

const StyledDiv = styled.div`
    height: 190px;
    width: 100%;
    text-align: center;
    padding: 2rem;
`

const StyledProfileDiv = styled.div`
    height: 150px;
    width: 100%;
    text-align: center;
    padding: 1rem;
`

type Props = {
  card: ICard
}

export const SimpleCard: React.FC<Props> = ({card}: Props) => {
  return (
    <StyledCard>
        <StyledImg fluid={card.img.fluid} alt={card.img.title} />
        <StyledDiv>
            <H3>{card.head}</H3>
            <StyledHtml
            dangerouslySetInnerHTML={{
              __html: card.description.childContentfulRichText.html,
            }}
          />
        </StyledDiv>
    </StyledCard>
  );
};

type ProfileProps = {
  profileInfo: IProfile
}
export const ProfileCard: React.FC<ProfileProps> = ({profileInfo}: ProfileProps) => {
  return (
    <Profile>
        <StyledProfileImg fluid={profileInfo.img.fluid} alt={profileInfo.img.title} />
        <StyledProfileDiv>
            <H3>{profileInfo.name}</H3>
            <TextSmall style={{color: theme.colors.brandLight.primary}} >{profileInfo.title}</TextSmall>
        </StyledProfileDiv>
    </Profile>
  );
};

