import FadeInContainer from '../components/shared/sectionAnimation';
import { graphql } from 'gatsby';
import { H2Bold } from '../components/shared/typography';
import Img from "gatsby-image"
import navigateTo from '../locales/pathHandler';
import { PageContext } from '../pageContext';
import Partners from '../components/shared/partners';
import React from 'react';
import ReviewCarousel from '../components/index/ReviewCarousel';
import Seo from '../components/Seo';
import {SimpleCard} from '../components/shared/card';
import SiteLayout from '../components/layout/layout';
import styled from '@emotion/styled';
import { theme } from '../components/shared/theme';

import { Col, Row } from 'antd';
import {
  ICard,
  IHomePage,
  ISimpleSection,
} from '../components/shared/contentfu.interface';

interface Props {
  data: {
    allContentfulHomePage: {
      nodes: IHomePage[]
    };
  };
  pageContext: PageContext;
}

const StyledHtml = styled.div`
  text-align: center;
  p {
    font-size: 14px;
    color: ${theme.colors.brandLight.primary};
  }
`

const StyledSection = styled.div`
  color: ${theme.colors.brandLight.dark};
  align-self: center;
    padding: 4rem;
  p {
    font-size: 14px;
  }
  h3 {
    width: 100%;
    font-size: 20px;
    line-height: 32px;
    color: ${theme.colors.brandLight.dark};
  }
`

const StyledSectionImg = styled(Img)`
  height: 450px;
  width: 100%;
  @media (max-width: 700px) {
    height: 400px;
    }
`

const Index: React.FC<Props> = ({ data, pageContext }: Props) => {
  const content = data.allContentfulHomePage.nodes[0];
  const contentfulSections: ISimpleSection[] =
    data.allContentfulHomePage.nodes[0].section;
  return (
    <SiteLayout
      locale={pageContext.locale}
      hasHeader={true}
      headerText={content.headline}
      headerImg={content.coverImg}
      componentName={pageContext.componentName}
    >
      <Seo
        title="PCR corona test"
        description="Privatcoronatest - Privatcoronatest.dk tilbyder private PCR Covid-19 test i hele landet for virksomheder, organisationer samt privatpersoner. Vi bruger udstyr af højeste kvalitet, samt korte svartider på test.
        Besøg vores testcenter på Islands Brygge, København og få svar på din coronatest med det samme."
        image={content.seoImg.file.url}
        lang={pageContext.locale}
        keywords={[
          'COVID-19',
          'Corona',
          'Privat',
          'Test',
          'Privatcoronatest',
          'Privat corona test',
          'Privat coronatest',
          'Danmark',
          'DK',
          'corona',
          'test',
          'organisation',
          'privat testing',
          'coronavirus',
          'corona test privat',
          'corona test firma',
          'test virksomhed',
        ]}
        pathname="/"
      />
      <Row style={{paddingTop: '2rem', paddingBottom: '2rem', paddingLeft: '1rem', paddingRight: '1rem', background: 'white' }} justify="center">
        <Col
          xs={{ span: 22, order: 2, offset: 0 }}
          md={{ span: 22, order: 2, offset: 0 }}
          lg={{ span: 22, order: 1, offset: 0 }}
          offset={1}
        >
          <H2Bold style={{textAlign: "center", color: theme.colors.brandLight.primary}}>{content.introHeadline}</H2Bold>
          <StyledHtml
            dangerouslySetInnerHTML={{
              __html: content.intro.childContentfulRichText.html,
            }}
          />
        </Col>
      </Row>
      <Row  style={{ paddingTop: '2rem', paddingBottom: '3rem', paddingLeft: '1rem', paddingRight: '1rem', background: 'white' }} justify="center">
        {content.cards.map((card: ICard, key: number) => {
          return (
            <Col
            key={key}
            style={{padding: '1rem'}}
            xs={{ span: 22 }}
            sm={{ span: 12 }}
            md={{ span: 8 }}
            lg={{ span: 6 }}
            onClick={() => navigateTo({locale: pageContext.locale}, key < 2 ? "booking" : "company")}
            >
              <SimpleCard card={card} />
            </Col>
          )
        })}
      </Row>
      {contentfulSections &&
        contentfulSections.map((s: ISimpleSection, key: number) => {
          return (
            <FadeInContainer transition={key % 2 == 0 ? 'XL' : 'XR'} key={key}>
              <Row style={{background: theme.colors.brandLight.forth}}>
              <Col xs={{span: 24, order: 1}} md={{span:12, order: key % 2 == 0 ? 1 : 0}} style={{display: 'flex'}}>
                  <StyledSection
                    dangerouslySetInnerHTML={{
                      __html: s.headline.childContentfulRichText.html,
                    }}
                  />
                </Col>
                <Col xs={{span: 24, order: 0}} md={{span:12, order: key % 2 == 0 ? 0 : 1}}>
                  <StyledSectionImg fluid={s.img.fluid} alt={s.img.title} />
                </Col>
              </Row>
            </FadeInContainer>
          );
        })}
      <Row gutter={[0, 24]} style={{ marginTop: '2rem', marginBottom: '3rem' }}>
        <Col>
          <ReviewCarousel
            title={"„"}
            reviews={content.reviews}
          />
        </Col>
      </Row>
      <FadeInContainer>
        <Partners partners={content.partners} />
      </FadeInContainer>
    </SiteLayout>
  );
};
export default Index;

export const query = graphql`
  query HomePageQuery($locale: String) {
    allContentfulHomePage(filter: { node_locale: { eq: $locale } }) {
      nodes {
        headline
        reviews {
          name
          text {
            text
          }
          company
        }
        coverImg {
            fluid (maxWidth: 2000) { ...GatsbyContentfulFluid }
          }
        seoImg {
          file {
            url
            fileName
          }
        }
        partners {
          title
          partner {
          companyName
            img {
              fluid (maxWidth: 400) { ...GatsbyContentfulFluid }
            }
          }
        }
        introHeadline
        intro {
          childContentfulRichText {
            html
          }
        }
        section {
          headline {
          childContentfulRichText {
            html
          }
        }
        img {
            fluid (maxWidth: 980) { ...GatsbyContentfulFluid }
          }
         
        }
        cards { 
          img {
            fluid (maxWidth: 600) { ...GatsbyContentfulFluid }
          }
          
          head
          description{
            childContentfulRichText {
              html
            }
          }
        }
      }
    }
}
`;
